#app-title-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 20pt;
  letter-spacing: 0;
  padding: 0;
  color: #3a3a3a;
}

#app-title-bold-big {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 32pt;
  letter-spacing: 0;
  padding: 0;
  color: #3a3a3a;
}

#app-title-light {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 20pt;
  letter-spacing: 0;
  padding: 0;
  color: #3a3a3a;
}

#app-title-light-big {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 32pt;
  letter-spacing: 0;
  padding: 0;
  color: #3a3a3a;
}

#menuitem {
  background: green;
  float: right;
}
